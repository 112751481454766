import { Button, Search } from "semantic-ui-react";
import {
  addEditSong,
  fetchSongSuggestions,
  searchSongs,
  songFetched,
} from "../../actions/songs";
import {
  searchRepertoireSong,
  setSongToCurrent,
} from "../../actions/repertoires";

import PropTypes from "prop-types";
import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
const ADD_EXISTING_SONG_LABEL = "- - -Add existing song - -";
const ADD_NEW_SONG_LABEL = "- - -Add new song - -";

class SongsSearch extends React.Component {
  typingTimer;
  doneTypingInterval = 500;

  constructor(props) {
    console.log("SongSearch constructor");
    super(props);

    this.searchRef = createRef(); // Create a ref
    this.state = {
      loading: false,
      value: "",
      open: false,
      showExisting: true,
      showSuggestions: true
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.searchRef.current && !this.searchRef.current.contains(event.target)) {
      this.setState({ open: false }); // Close the suggestions
    }
  };
  
  handleSearchChange = (e, data) => {
    this.setState({ value: data.value, open: true, showExisting: true, showSuggestions: true });

    if (data.value.trim() !== "") {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        () => this.triggerSearch(data.value.trim()),
        this.doneTypingInterval
      );
    }
  };

  triggerSearch = (term) => {
    this.props.searchRepertoireSong(term);
    this.props.searchSongs(term);
    this.props.fetchSongSuggestions({ title: term });
  };

  handleSearchSelect = (e, data) => {
    console.log("handleSearchSelect", data);
    if (!data || !data.result || !data.result.song) return;
    if (data.result.song.type === "label") {
      console.log("handleSearchSelect label");
      switch (data.result.song.singer) {
        case ADD_EXISTING_SONG_LABEL:
          this.setState({ showExisting: !this.state.showExisting });
          break;
        case ADD_NEW_SONG_LABEL:
          this.setState({ showSuggestions: !this.state.showSuggestions });
          break;
      }
      e.preventDefault();
      this.setState({ open: true });
      return;
    }
    this.setState({ open: false, showExisting: true, showSuggestions: true });
    let song = data.result.song;

    switch (data.result.song.type) {
      case "repertoireSearchedSongs":
        console.log("repertoireSearchedSongs existing in repertoire");
        this.props
          .setSongToCurrent(
            { id: song.song_repertoire.repertoire_id },
            { id: song.id }
          )
          .then(() => {
            console.log(
              "repertoireSearchedSongs existing in repertoire throw event"
            );
            const event = new CustomEvent("current_song_changed", {
              detail: {
                repertoire_id: song.song_repertoire.repertoire_id,
                song_id: song.id,
              },
            });
            document.dispatchEvent(event);
          });
        return;
      case "songPropositions":
        console.log("handleSearchSelect existing");
        this.props.songFetched(song);
        break;
      case "songSuggestions":
        console.log("handleSearchSelect new");
        song.id = "new";
        this.props.addEditSong(song);
        break;
      default:
        break;
    }
    if (!this.props.history.location.pathname) return;
    const regex = /.(repertoire|genre)(=|\/)(\d+)/;
    let match = this.props.history.location.pathname.match(regex);
    if (!match) return;
    const [_, entity, _1, value] = match;
    this.props.history.push(`/song/${song.id}?${entity}=${value}`);
  };

  addType(songs, type) {
    if (!songs) return [];
    for (let i = 0; i < songs.length; i++) {
      songs[i].type = type;
    }
    return songs;
  }

  render() {
    const { loading, value, open, showExisting, showSuggestions } = this.state;
    let {
      repertoireSearchedSongs,
      songPropositions,
      songSuggestions,
    } = this.props;

    const songs = [
      ...this.addType(repertoireSearchedSongs, "repertoireSearchedSongs"),
      { singer: ADD_EXISTING_SONG_LABEL, title: "",type: "label"},
      ...(showExisting? this.addType(songPropositions, "songPropositions"): []),
      { singer: ADD_NEW_SONG_LABEL, title: "", type: "label" },
      ...(showSuggestions? this.addType(songSuggestions, "songSuggestions"): []),
    ];

    return (
      <div ref={this.searchRef}>
        <Search
          loading={loading}
          noResultsMessage="Nema rezultata!"
          onResultSelect={(e, data) => this.handleSearchSelect(e, data)}
          onSearchChange={this.handleSearchChange}
          results={songs.map((x, i) => {
            return {
              title: x.singer + " - " + x.title,
              key: i,
              song: x,
            };
          })}
          open={open}
          value={value}
          icon={
            !value ? (
              <i class="search icon" />
            ) : (
              <Button
                size="small"
                compact={true}
                onClick={() => this.setState({ value: "" })}
                style={{
                  position: "absolute",
                  right: 0,
                  padding: "3px",
                  margin: "10px",
                }}
              >
                <i class="close icon" />
              </Button>
            )
          }
        />
      </div>
    );
  }
}

SongsSearch.propTypes = {
  repertoireSearchedSongs: PropTypes.array.isRequired,
  songSuggestions: PropTypes.array.isRequired,
  songPropositions: PropTypes.array.isRequired,
  searchSongs: PropTypes.func.isRequired,
  fetchSongSuggestions: PropTypes.func.isRequired,
  searchRepertoireSong: PropTypes.func.isRequired,
  addEditSong: PropTypes.func.isRequired,
  setSongToCurrent: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    repertoireSearchedSongs: state.repertoireSearchedSongs,
    songSuggestions: state.songSuggestions,
    songPropositions: state.songPropositions,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      searchRepertoireSong,
      searchSongs,
      fetchSongSuggestions,
      addEditSong,
      songFetched,
      setSongToCurrent,
    }
  )(SongsSearch)
);
